











import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class Page404 extends Vue {
  second: number = 5

  mounted() {
    let interval = setInterval(() => {
      this.second -= 1
      if (this.second === -1) {
        this.$nextTick(() => {
          this.toHome()
          clearInterval(interval)
        })
      }
    }, 1000);
  }
  toHome() {
    this.$router.replace({ name: 'home' })
  }
}
